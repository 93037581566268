"use strict"
import api from 'api/api';
import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { PermisGet } from 'views/employee/list-permis/component/TablePermis';

interface User {
  name: string;
}

 interface Parent {
 
    id: string;
    name: string;
    email: string | null;
    phoneNumber: string | null;
    address: string | null;
    enterpriseId : string | null
    enterpriseName : string | null
}

interface AppContextProps {
  role: string | null;
  setRole: (role: string | null) => void;
  setEmailEntreprise: (emailEntreprise: string | null) => void;
  setId: (id: string | null) => void;
  setUser: (user: User | null) => void;
  setLogoPreview: (logoPreview: string) => void;
  setPermisList : (listPermit : PermisGet[]) => void
  loading: boolean;
  emailEntreprise : string | null;
  id : string;
  user : User;
  parent : Parent;
  logoPreview:string;
  permisList:PermisGet[]
}

const AppContext = createContext<AppContextProps>({
  role: null,
  setRole: () => {},
  setId: () => {},
  setEmailEntreprise: () => {},
  setUser: () => {},
  setLogoPreview: () => {},
  setPermisList:() => {},
  logoPreview:'',
  loading: true,
  emailEntreprise : null,
  id :"",
  user :{ name : "" , },
  parent : null,
  permisList : []
});

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [role, setRole] = useState<string | null>(null);
  const [emailEntreprise, setEmailEntreprise] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [id, setId] = useState<string>("");
  const [user, setUser] = useState<User>({name : "" });
  const [parent , setParent] = useState<Parent>(null)
  const [logoPreview, setLogoPreview] = useState<string>(null);
  const [permisList, setPermisList] = useState<PermisGet[]>([]);

  const setToken=async()=>{
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = await JSON.parse(atob(token.split('.')[1]));
      
      setId(decodedToken?.certserialnumber)
      if(typeof decodedToken?.role === "string" && decodedToken?.role.length>2){
        setRole(decodedToken?.role.toLowerCase());
      }
      setEmailEntreprise(decodedToken?.email)
      getUser(decodedToken.certserialnumber ,decodedToken.role )
    }
    setLoading(false); 
  }

  useEffect(() => {
    setToken()
  }, [role,id]);

  const getUser = async (id : string , role:string) => {
    try {
      const {data} = await api.get(`${role}/${id}`)
  
      if(data){
      
        setUser(prevUser => ({ ...prevUser, name: data.name }));
        if(role === 'Employee' ){
          
          setParent(data.fournisseur)
          
        }else if(role === "SuperResponsible"){
          setParent(data.divisions)
        }else if(role === "Responsable"){
          setParent(data.zones)
        }
        else if(role === "TopResponsible"){
          setParent(data.divisions)
        }

      }
    } catch (error) {
      console.log(error);
      
    }
  }
 

  return (
    <AppContext.Provider value={{ role, setRole, loading , emailEntreprise ,setEmailEntreprise, id,setId, user,setUser , parent,logoPreview,setLogoPreview , setPermisList ,permisList}}>
      {children}
    </AppContext.Provider>
  );
};

export const useRole = () => {
  return useContext(AppContext);
};
